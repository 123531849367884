import * as React from "react"
import {useEffect} from 'react';
import jQuery from 'jquery';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';

import '../css/style.css';
import '../css/responsive.css';

import logoLight from '../img/asians-group-light.png';
import logoDark from '../img/asians-group-dark.png';
import hero from '../img/hero-mockup.png';
import aboutImg from '../img/about.png';
import overviewImg from '../img/overview.png';
import homeBottomShape from '../img/home-bottom-shape.png';

function initLanguageSwitcher() {
  let lang = window.location.pathname.replace(/\//g, '');
  if (!lang.trim().length)
    lang = 'en';

  jQuery(`#language-switcher option[value=${lang}]`).attr('selected', true);

  jQuery("#language-switcher").change(function (x) {
    const toLang = jQuery("#language-switcher option:selected").val();
    if (toLang === 'en') {
      window.location = '/';
      return;
    }

    window.location = `/${toLang}/`;
  });
}

// markup
const IndexPage = () => {
  const {t} = useTranslation();

  // on component mount
  useEffect(() => {
    initLanguageSwitcher();

    // trigger handleScroll on initial render
    handleScroll();

    window.addEventListener('scroll', handleScroll);
  }, []);

  function handleScroll() {
    var pixels = 50;
    var top = 1200;
    if (jQuery(window).scrollTop() > pixels) {
      jQuery('.navbar-expand-md').addClass('navbar-reduce');
      jQuery('.navbar-expand-md').removeClass('navbar-trans');
    } else {
      jQuery('.navbar-expand-md').addClass('navbar-trans');
      jQuery('.navbar-expand-md').removeClass('navbar-reduce');
    }
    if (jQuery(window).scrollTop() > top) {
      jQuery('.scrolltop-mf').fadeIn(1000, "easeInOutExpo");
    } else {
      jQuery('.scrolltop-mf').fadeOut(1000, "easeInOutExpo");
    }
  }

  function handleAffiliateProgramClick() {
    window.location = `${window.location.pathname}affiliate-program/`;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{t('Asian Cloud CDN, high speed, high defense, high stability!')}</title>
        <link rel="canonical" href="https://asians.group"/>
        <meta name="keywords"
              content="domains, registration, best cdn, cdn, service, free, node, ssl, security, mitigation"/>
        <meta name="description"
              content="Asian Cloud CDN provides one-stop solution to CDN acceleration and defense, DNS service, certificate service. Making website management extremely simple."/>
      </Helmet>

      <div className="page-wrapper">
        <nav className="navbar navbar-b navbar-trans navbar-expand-md fixed-top d-flex flex-column" id="mainNav">
          <div className="affiliate-banner d-flex justify-content-center py-2 rounded-lg shadow" style={{ backgroundColor: '#fffede' }}>
            <a href="#" onClick={handleAffiliateProgramClick} className="px-4 text-white">📢 {t('JOIN_AFFILIATE_PROGRAM_BANNER')}</a>
          </div>

          <div className="container align-items-baseline">
            <a className="navbar-brand js-scroll" href="/">
              <img className="navbar-brand-regular" src={logoLight} width="190"
                   alt="brand-logo-white"/>
              <img className="navbar-brand-dark" src={logoDark} width="190"
                   alt="brand-logo-dark"/>
            </a>

            <select id="language-switcher">
              <option value="en">en</option>
              <option value="ko">ko</option>
              <option value="zh">zh</option>
            </select>

            <div className="navbar-collapse collapse justify-content-end" id="navbarDefault">
              <ul className="navbar-nav">
                <li className="nav-item"><a className="nav-link js-scroll active" href="#home"><Trans>Home</Trans></a>
                </li>
                <li className="nav-item"><a className="nav-link js-scroll" href="#security"><Trans>Security</Trans></a>
                </li>
                <li className="nav-item"><a className="nav-link js-scroll" href="#our-tech"><Trans>Our Tech</Trans></a>
                </li>
                <li className="nav-item"><a className="nav-link js-scroll" href="#services"><Trans>Services</Trans></a>
                </li>
                <li className="nav-item"><a className="nav-link js-scroll" href="#price"><Trans>Pricing</Trans></a></li>
                <li id="userprofile" className="nav-item top-button" style={{display: 'none'}}>
                  <a href="https://console.asians.group/" id="username"
                     className="nav-link js-scroll"><Trans>Console</Trans></a>
                </li>
                <li id="loginpanel" className="nav-item top-button">
                  <a href="https://console.asians.group/" className="nav-link js-scroll">
                    <Trans>Login</Trans>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <header id="home"
                className="home-area hero-equal-height section-padding overflow-hidden d-flex align-items-center">

          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="text-left home-content z-index position-relative">
                  <h1><Trans>Asian Cloud CDN, high speed, high defense, high stability!</Trans></h1>
                  <p>
                    <Trans>SiteDescription</Trans>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="text-center z-index position-relative home-image">
                  <img src={hero} className="img-fluid" alt=""/>
                </div>
              </div>
            </div>
          </div>
          <div className="svg-shape-bottom">
            <img src={homeBottomShape} className="bottom-shape img-fluid" alt=""/>
          </div>
        </header>

        <section id="security" className="about-area section-padding">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="about-img animation-bounce">
                  <img src={aboutImg} className="img-fluid" alt=""/>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="about-info">
                  <h6 className="big-title text-center w-100"><Trans>Top notch security</Trans></h6>
                  <h2 className="text-center"><Trans>Protect your websites with our best in class security features</Trans></h2>
                  <div className="row d-flex align-items-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 features-item-box">
                      <div className="features-item">
                        <div className="features-icon pulse-p"/>
                        <div className="features-info">
                          <h3><Trans>Shield detection</Trans></h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 features-item-box">
                      <div className="features-item">
                        <div className="features-icon pulse-r"/>
                        <div className="features-info">
                          <h3><Trans>Rate limiting</Trans></h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 features-item-box">
                      <div className="features-item">
                        <div className="features-icon pulse-b"/>
                        <div className="features-info">
                          <h3><Trans>Bot detection</Trans></h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 features-item-box">
                      <div className="features-item">
                        <div className="features-icon pulse-o"/>
                        <div className="features-info">
                          <h3><Trans>Geo restriction</Trans></h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 features-item-box">
                      <div className="features-item">
                        <div className="features-icon pulse-g"/>
                        <div className="features-info">
                          <h3><Trans>IP restriction</Trans></h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div id="our-tech" className="works-area section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="section-title">
                  <h2>
                    <Trans>Use the new generation of web technology to make your website stand-out and protected</Trans>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-lg-4 col-md-6 col-sm-12 works-item-box">
                <div className="works-item">
                  <div className="works-icon"><span className="pulse-p"><i className="fa fa-fast-forward"/></span>
                  </div>
                  <div className="works-info">
                    <h3><Trans>Website acceleration</Trans></h3>
                    <p><Trans>WebsiteAccelerationDescription</Trans></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 works-item-box">
                <div className="works-item">
                  <div className="works-icon"><span className="pulse-g"><i className="fa fa-shield"/></span></div>
                  <div className="works-info">
                    <h3><Trans>Infinite defense</Trans></h3>
                    <p><Trans>InfiniteDefenseDescription</Trans>
                    </p>
                  </div>
                  <div className="works-info-bottom"><i className="ti-angle-double-down"/></div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 works-item-box">
                <div className="works-item">
                  <div className="works-icon"><span className="pulse-o"><i className="fa fa-plug"/></span></div>
                  <div className="works-info">
                    <h3><Trans>One-stop integration</Trans></h3>
                    <p><Trans>OneStopIntegrationDescription</Trans></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="services" className="overview-area section-padding">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="overview-img text-center">
                  <img src={overviewImg} className="img-fluid" alt=""/>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="overview-info">
                  <h6 className="big-title text-center w-100"><Trans>WeAreTheBest</Trans></h6>
                  <h2 className="text-center"><Trans>Our services at your disposal</Trans></h2>
                  <div className="row">
                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <div className="why-choos-list">
                        <i className="fa fa-check-circle"/>
                        <span><Trans>Unlimited high-speed CDN</Trans></span>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <div className="why-choos-list">
                        <i className="fa fa-check-circle"/>
                        <span><Trans>DNS Hosting</Trans></span>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <div className="why-choos-list">
                        <i className="fa fa-check-circle"/>
                        <span><Trans>Free Certificate</Trans></span>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <div className="why-choos-list">
                        <i className="fa fa-check-circle"/>
                        <span><Trans>DDOS & CC Attack Prevention</Trans></span>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <div className="why-choos-list">
                        <i className="fa fa-check-circle"/>
                        <span><Trans>24/7 Support / Chat Support</Trans></span>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <div className="why-choos-list">
                        <i className="fa fa-check-circle"/>
                        <span><Trans>Custom Region Coverage as per need</Trans></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="price" className="price-area section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 section-title">
                <h6 className="big-title"><Trans>Price Plan</Trans></h6>
                <h2><Trans>Pricing</Trans></h2>
              </div>
            </div>
            
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 price-item">
                <div className="single-price-item">
                  <div className="pricing-header">
                    <h3 className="pricing-title"><Trans>Free</Trans></h3>
                  </div>
                  <div className="pricing-body">
                    <div className="price-wrapper">
                      <span className="currency">$</span>
                      <span className="price">0</span>
                      <span className="period">/<Trans>Free</Trans></span>
                    </div>
                    <ul className="list">
                      <li><Trans>Acceleration speed</Trans>
                        <span className="badge badge-success badge-pill">Yes</span>
                      </li>
                      <li><Trans>Domain limit</Trans>
                        <span className="badge badge-success badge-pill">3</span>
                      </li>
                      <li className="disabled"><Trans>Upgradable domains</Trans></li>
                      <li><Trans>CC attack prevention</Trans>
                        <span className="badge badge-primary badge-bill">5,000 QPS</span>
                      </li>
                      <li><Trans>DDOS attack prevention</Trans>
                        <span className="badge badge-primary badge-bill">8G</span>
                      </li>
                      <li className="disabled"><Trans>Generic domain name</Trans></li>
                      <li><Trans>Free traffic</Trans>
                        <span className="badge badge-success badge-pill">10G</span>
                      </li>
                      <li className="disabled"><Trans>Support</Trans></li>
                      <li className="disabled"><Trans>Service fee</Trans></li>
                    </ul>
                  </div>
                  <div className="pricing-footer">
                    <a href="https://console.asians.group" className="button"><Trans>Activate now</Trans></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 price-item">
                <div className="single-price-item active">
                  <div className="pricing-header">
                    <h3 className="pricing-title"><Trans>Pro</Trans></h3>
                  </div>
                  <div className="pricing-body">
                    <div className="price-wrapper">
                      <span className="currency">$</span>
                      <span className="price">1,280</span>
                      <span className="period">/<Trans>Month</Trans></span>
                    </div>
                    <ul className="list">
                      <li><Trans>Acceleration speed</Trans>
                        <span className="badge badge-success badge-pill">Yes</span>
                      </li>
                      <li><Trans>Domain limit</Trans>
                        <span className="badge badge-success badge-pill">28</span>
                      </li>
                      <li><Trans>Upgradable domains</Trans>
                        <span className="badge badge-success badge-pill">138</span>
                      </li>
                      <li><Trans>CC attack prevention</Trans>
                        <span className="badge badge-primary badge-bill">100,000 QPS</span>
                      </li>
                      <li><Trans>DDOS attack prevention</Trans>
                        <span className="badge badge-primary badge-bill">200G</span>
                      </li> 
                      <li><Trans>Generic domain name</Trans>
                        <span className="badge badge-success badge-pill">Yes</span>
                      </li>
                      <li><Trans>Free traffic</Trans>
                        <span className="badge badge-success badge-pill">2T</span>
                      </li>
                      <li><Trans>Support</Trans>
                        <span className="badge badge-primary badge-bill">Telegram, Skype</span>
                      </li>
                      <li><Trans>Service fee</Trans>
                        <span className="badge badge-primary badge-bill"><Trans>Agreed</Trans></span>
                      </li>
                    </ul>
                  </div>
                  <div className="pricing-footer">
                    <a href="https://console.asians.group" className="button"><Trans>Activate now</Trans></a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 price-item">
                <div className="single-price-item active">
                  <div className="pricing-header">
                    <h3 className="pricing-title"><Trans>Business</Trans></h3>
                  </div>
                  <div className="pricing-body">
                    <div className="price-wrapper">
                      <span className="currency">$</span>
                      <span className="price">4,980</span>
                      <span className="period">/<Trans>Month</Trans></span>
                    </div>
                    <ul className="list">
                      <li><Trans>Acceleration speed</Trans>
                        <span className="badge badge-success badge-pill">Yes</span>
                      </li>
                      <li><Trans>Domain limit</Trans>
                        <span className="badge badge-success badge-pill">138</span>
                      </li>
                      <li><Trans>Upgradable domains</Trans>
                        <span className="badge badge-success badge-pill">9999</span>
                      </li>
                      <li><Trans>CC attack prevention</Trans>
                        <span className="badge badge-primary badge-bill">1,000,000 QPS</span>
                      </li>
                      <li><Trans>DDOS attack prevention</Trans>
                        <span className="badge badge-primary badge-bill">1T</span>
                      </li>
                      <li><Trans>Generic domain name</Trans>
                        <span className="badge badge-success badge-pill">Yes</span>
                      </li>
                      <li><Trans>Free traffic</Trans>
                        <span className="badge badge-success badge-pill">8T</span>
                      </li>
                      <li><Trans>Support</Trans>
                        <span className="badge badge-primary badge-bill">Telegram, Skype</span>
                      </li>
                      <li><Trans>Service fee</Trans>
                        <span className="badge badge-primary badge-bill"><Trans>Agreed</Trans></span>
                      </li>
                    </ul>
                  </div>
                  <div className="pricing-footer">
                    <a href="https://console.asians.group" className="button"><Trans>Activate now</Trans></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="faq-area section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 section-title">
                <h6 className="small-title"><Trans>FAQs</Trans></h6>
                <h2><Trans>Frequently Asked Questions</Trans></h2>
              </div>
              <div className="col-lg-6 col-md-12 faq-item first-faq-box">
                <Collapse title={t("Can I purchase additional domain names?")}
                          description={t("Accelerated domain name, effective on a monthly basis, $2 / month / each.")}/>
              </div>
              <div className="col-lg-6 col-md-12 faq-item">
                <Collapse title={t("Is free CDN a site-wide acceleration?")}
                          description={t("Yes, free CDN is site-wide acceleration.")}/>
              </div>
            </div>
          </div>
        </section> */}

        <section id="contact" className="contact-area section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 section-title">
                <h6 className="small-title"><Trans>Get In Touch</Trans></h6>
                <h2><Trans>Contact Us</Trans></h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="contact-info">
                  <div className="row">
                    <div className="offset-md-3 col-md-3">
                      <div className="d-flex">
                        <div className="icon">
                          <div className="works-icon"><span className="pulse-b">
                                            <i className="fa fa-telegram" aria-hidden="true"/></span></div>


                        </div>
                        <div className="c-area">
                          <span>Telegram</span>
                          <p><a href="https://t.me/ac_ludwig" rel="nofollow" target="_blank">Telegram:@ac_ludwig</a></p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-3">
                      <div className="d-flex">
                        <div className="icon">
                          <div className="works-icon"><span className="pulse-b">
                                            <i className="fa fa-skype" aria-hidden="true"/></span>
                          </div>
                        </div>
                        <div className="c-area">
                          <span>Skype</span>
                          <p><a href="skype:live:.cid.a38bfb9f7d3c17d0?chat">live:.cid.a38bfb9f7d3c17d0</a></p>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-3">
                      <div className="d-flex">
                        <div className="icon">
                          <div className="works-icon"><span className="pulse-b">
                                            <i className="fa fa-envelope" aria-hidden="true"/></span>
                          </div>
                        </div>
                        <div className="c-area">
                          <span>Email</span>
                          <p><a href="mailto:sales@asians.cloud">Email:sales@asians.cloud</a></p>
                        </div>
                      </div>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

setTimeout(function(){
  const toLang = jQuery("#language-switcher option:selected").val();
  if (toLang == "zh") {
    jQuery("#asian_document").attr("href", "https://docs.asians.group/cdn-yong-hu-shou-ce/");
  } else {
    jQuery("#asian_document").attr("href", "https://docs.asians.group/");
  }
}, 1000);